export const environment = {
    production: false,
    apiUrl: 'https://backqa-dot-qa-web-ce0-alas.ue.r.appspot.com/api',
    webUrl: 'https://qa.web.alasxpress.com/',
    dashboardUrl: 'https://dashboard.alasxpress.com/auto-login/',
    jsPrintManagerLicence: 'https://backqa-dot-qa-web-ce0-alas.ue.r.appspot.com/api/jsprint/jsp',
    fireURL: 'https://qa-api-ce0-alas.firebaseio.com/',
    firebaseAccessConfig: {
      apiKey: 'AIzaSyD-J87xIemErNBIkFXluG-TadI2eSRF6Rk',
      authDomain: 'qa-api-ce0-alas.firebaseapp.com',
      databaseURL: 'https://qa-api-ce0-alas.firebaseio.com',
      projectId: 'qa-api-ce0-alas',
      storageBucket: 'qa-api-ce0-alas.appspot.com',
      messagingSenderId: '382334332977',
      appId: '1:1020182247138:web:cb61846f4ff906aadf10bb',
      serviceAccount: 'web_config/qa-firebase.json'
    },
  };
  